import { useQuery } from '@tanstack/vue-query';
import { getOrderById } from '@/api/user';

const useGetOrderById = (orderId: Ref<string | undefined>) => {
  const enabled = computed(() => !!orderId.value);

  return useQuery({
    queryFn: () => getOrderById(orderId.value!),
    queryKey: ['orderById', orderId.value ?? ''],
    enabled,
  });
};

export default useGetOrderById;
