import { useQuery } from '@tanstack/vue-query';
import { getUserDetails } from '@/api/user';

const useGetUserDetails = () => {
  return useQuery({
    queryKey: ['userDetails'],
    queryFn: () => getUserDetails(),
    enabled: true,
    retry: 0,
  });
};

export default useGetUserDetails;
