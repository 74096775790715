import { gql } from 'graphql-request';

export const customerRecoverEmail = gql`
	mutation customerRecover($email: String!) {
		customerRecover(email: $email) {
			customerUserErrors {
				code
			}
		}
	}
`;
