import { defineStore } from 'pinia';
import useFetchUserDetails from '@/api/queries/getUserDetails';
import { camelizeKeys } from 'humps';
import useFetchSubscriptions from '@/api/queries/getSubscriptions';
import useFetchOrderById from '@/api/queries/getOrderById';
import { PinsaSubscription } from '@/types/pinsaTypes';
import { Subscription } from '@/types/types';
import { lastSubscriptionOrder, transformSubscription } from '@/api/utils';

export const useUserStore = defineStore('userStore', () => {
  const { data, refetch: getUserDetails } = useFetchUserDetails();

  const attributes = computed(() => camelizeKeys(data.value?.data?.attributes));

  const email = computed(() => attributes.value?.email);
  const fullName = computed(() => `${attributes.value?.firstName} ${attributes.value?.lastName}`);
  const userId: ComputedRef<string | undefined> = computed(() => attributes.value?.extId);
  const phone = computed(() => attributes.value?.phone);
  const acceptsFreeGifts = computed(() => attributes.value?.acceptsFreeGifts);
  const defaultAddressId = computed(() => data.value?.data?.relationships?.default_address?.data?.id);

  const { subscriptions: subs, selectedSubscription, getUserSubscriptions } = useFetchSubscriptions();

  const subscriptions = computed(() => subs.value?.map((sub: PinsaSubscription) => sub.attributes));
  const hasSubscription = computed(() => subscription.value && subscriptions.value?.length > 0);
  const lastOrderId = computed(() => selectedSubscription.value?.data.relationships?.last_order?.data?.id);

  const { data: order } = useFetchOrderById(lastOrderId);
  const lastOrder = computed(() => {
    if (!order.value) return undefined;
    return lastSubscriptionOrder(order.value?.data);
  });

  const subscription: Ref<Subscription | undefined> = computed(() => {
    return transformSubscription(selectedSubscription.value);
  });

  return {
    email,
    fullName,
    userId,
    phone,
    hasSubscription,
    acceptsFreeGifts,
    attributes,
    defaultAddressId,
    subscriptions,
    subscription,
    getUserDetails,
    getUserSubscriptions,
    lastOrder,
  };
});
